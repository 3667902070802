import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import './assets/taiwind.css'
import router from './router'
import store from '@/store/index.js';
import { initFlowbite } from 'flowbite'
import 'ant-design-vue/dist/reset.css';
import './assets/base.css'

const app = createApp(App)
 
app.use(initFlowbite)
app.use(router)
app.use(store);
app.use(Antd);
app.mount('#app')