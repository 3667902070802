<script setup>
import { RouterLink, RouterView } from 'vue-router'
import HelloWorld from '../components/HelloWorld.vue'
import Aside from '../components/Aside.vue'
import { ref, provide } from 'vue';
import { APP_PROVIDE } from '@/const/provide'

const isSpinning = ref(false);
provide(APP_PROVIDE, { isSpinning })
</script>
<template>
  <!-- Nếu đã đăng nhập, hiển thị nội dung bên dưới -->
  <div class="bg-gray-100">
    <Aside />
    <header class="bg-white shadow sm:ml-64">
      <div class="max-w-9xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <h2 class="text-xl font-semibold leading-tight text-gray-800">Văn Miếu Đêm</h2>
      </div>
    </header>
    <div class="hah">
      <a-spin :spinning="isSpinning" style="z-index: 10000 !important;">
        <div class="mx-auto w-full sm:px-6 lg:px-8 set__hieght">
          <div class="p-4 relative">
            <RouterView />
          </div>
        </div>
      </a-spin>
    </div>
  </div>
</template>


<style scoped>
.set__hieght {
  height: calc(100vh - 73px);
  overflow-y: scroll;
}

.set__hieght::-webkit-scrollbar {
  display: none;
  /* cho Chrome, Safari, and Opera */
}

.hah {
  margin-left: 256px;

}

@media only screen and (max-width: 600px) {
  .hah {
    margin-left: 0;
  }
}
</style>